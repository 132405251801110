<template>
  <div id="settings_page_wrapper">
    <div class="bb-mobile-header">
      <div class="bb-mobile-header-left">
        <router-link
          :to="{ name: 'settings' }"
          v-show="activeTab && !selectedPlace"
        >
          <button class="mobile-sidebar-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#253342"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-arrow-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </button>
        </router-link>
        <button
          class="mobile-sidebar-btn"
          v-show="activeTab && selectedPlace"
          @click="unsetSelectedPlace()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#253342"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </button>
        <button
          class="mobile-sidebar-btn"
          @click="openMobileAside()"
          v-show="!activeTab"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
      <h1>{{ mobileHeaderTitle }}</h1>
      <div class="bb-mobile-header-right">
        <button
          class="mobile-selected-place-actions-btn"
          v-show="activeTab && selectedPlace"
          @click="showSelectedPlaceMobileActions()"
        >
          <i class="ki ki-more-ver"></i>
        </button>
      </div>
    </div>
    <div class="mobile-tabs-select" v-show="!activeTab">
      <router-link
        to="/settings/account-settings"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
      >
        <div class="mobile-tab-select-item">
          <div class="tab-description">
            <p>{{ $t("settings.mobileTabsSelect.account.title") }}</p>
            <span>{{
              $t("settings.mobileTabsSelect.account.description")
            }}</span>
          </div>
          <button>
            <i class="la la-angle-right"></i>
          </button>
        </div>
      </router-link>
      <router-link
        to="/settings/locations"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount) ||
            $hasPermission(acl.permissions.managePlace)
        "
      >
        <div class="mobile-tab-select-item">
          <div class="tab-description">
            <p>{{ $t("settings.mobileTabsSelect.locations.title") }}</p>
            <span>{{
              $t("settings.mobileTabsSelect.locations.description")
            }}</span>
          </div>
          <button>
            <i class="la la-angle-right"></i>
          </button>
        </div>
      </router-link>
      <!-- <router-link
        to="/settings/billing"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
      >
        <div class="mobile-tab-select-item">
          <div class="tab-description">
            <p>{{ $t("settings.mobileTabsSelect.billing.title") }}</p>
            <span>{{
              $t("settings.mobileTabsSelect.billing.description")
            }}</span>
          </div>
          <button>
            <i class="la la-angle-right"></i>
          </button>
        </div>
      </router-link> -->
    </div>
    <div class="tabs">
      <AccountSettingsTab
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
        v-show="activeTab === 'account-settings'"
      />
      <LocationsTab
        ref="locationsTab"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount) ||
            $hasPermission(acl.permissions.managePlace)
        "
        v-show="activeTab === 'locations'"
        @placeSelected="placeSelected($event)"
      />
      <!-- <BillingTab
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
        v-show="activeTab === 'billing'"
      /> -->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AccountSettingsTab from "@/view/pages/components/Settings/AccountSettingsTab.vue";
import LocationsTab from "@/view/pages/components/Settings/LocationsTab.vue";
// import BillingTab from "@/view/pages/components/Settings/BillingTab.vue";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";

export default {
  name: "Settings",
  components: {
    AccountSettingsTab,
    LocationsTab,
    // BillingTab
  },
  mixins: [mobileAsideMixin],
  data() {
    return {
      availableTabs: ["account-settings", "locations"],
      activeTab: null,
      selectedPlace: null
    };
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("settings.title") }
    ]);
    // Set active tab
    if (window.innerWidth > 992) {
      if (
        this.$hasRole(this.acl.roles.owner) ||
        this.$hasPermission(this.acl.permissions.manageAccount)
      ) {
        if (this.$route.params.tab) {
          if (this.availableTabs.includes(this.$route.params.tab)) {
            this.activeTab = this.$route.params.tab;
          } else {
            this.activeTab = "account-settings";
          }
        } else {
          this.activeTab = "account-settings";
        }
      } else if (this.$hasPermission(this.acl.permissions.managePlace)) {
        //this.$route.params.tab = "locations";
        this.activeTab = "locations";
      } else {
        this.$router.push({
          name: "calendar"
        });
      }
    }
  },
  methods: {
    /**
     * Generate mobile header title based on active tab
     * @return {String}
     */
    generateMobileHeaderTitle() {
      if (!this.activeTab) {
        return this.$t("settings.title");
      }
      if (this.selectedPlace) {
        return this.selectedPlace.name;
      }
      let words = this.activeTab.split("-");
      return this.$t("settings.tabs." + words[0]);
    },

    /**
     * Set selected place on placeSelected locations tab event
     * @param {Object} place
     * @return {void}
     */
    placeSelected(place) {
      this.selectedPlace = place;
    },

    /**
     * Unset selected place
     * @return {void}
     */
    unsetSelectedPlace() {
      this.selectedPlace = null;
      this.$refs.locationsTab.selectedPlace = null;
    },

    /**
     * Show mobile actions for selected place
     * @return {void}
     */
    showSelectedPlaceMobileActions() {
      this.$refs.locationsTab.showMobileActions();
    }
  },
  computed: {
    /**
     * Return mobile header title
     * @return {String}
     */
    mobileHeaderTitle() {
      return this.generateMobileHeaderTitle();
    }
  },
  watch: {
    /**
     * Watch for route tab param change
     */
    "$route.params.tab": function(tab) {
      if (this.availableTabs.includes(tab)) {
        this.activeTab = tab;
      } else {
        if (window.innerWidth > 992) {
          this.activeTab = "account-settings";
        } else {
          this.activeTab = null;
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/sass/bb-styles/pages/settings.scss";
</style>

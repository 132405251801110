<template>
  <b-modal
    id="place_images_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
    :hide-footer="true"
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <div class="mobile-header-placeholder"></div>
        <h2>{{ $t("placeImagesModal.title") }}</h2>
        <div class="d-flex align-items-center">
          <button
            class="close-modal-btn mr-7"
            id="place_images_modal_close_btn"
            @click="
              close();
              reset();
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <button class="bb-btn btn-success header-save-btn" @click="submit()">
          {{ $t("placeImagesModal.saveBtn") }}
        </button>
      </div>
      <div class="modal-header-title-container">
        <h2>{{ $t("placeImagesModal.title") }}</h2>
      </div>
    </template>
    <div class="d-flex justify-content-center align-items-center">
      <div class="bb-card">
        <div class="card-header">
          <span class="card-title">{{
            $t("placeImagesModal.card.title")
          }}</span>
        </div>
        <input
          type="file"
          style="display:none"
          ref="imageInput"
          @change="onFileChange"
          multiple
        />
        <div class="card-body">
          <div class="image-item" v-for="image in place.images" :key="image.id">
            <img :src="image.url" />
          </div>
          <div
            class="image-item"
            v-for="(image, index) in uploadedImages"
            :key="index"
          >
            <img :src="image.url" />
          </div>
          <div class="image-item" @click="uploadImage()">
            <div class="image-placeholder">
              <svg
                width="44"
                height="48"
                viewBox="0 0 44 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.7059 7.21622H5.94118C4.63069 7.21622 3.37389 7.74015 2.44724 8.67277C1.52059 9.60538 1 10.8703 1 12.1892V37.0541M1 37.0541V42.027C1 43.3459 1.52059 44.6108 2.44724 45.5434C3.37389 46.4761 4.63069 47 5.94118 47H35.5882C36.8987 47 38.1555 46.4761 39.0822 45.5434C40.0088 44.6108 40.5294 43.3459 40.5294 42.027V32.0811M1 37.0541L12.3301 25.651C13.2567 24.7187 14.5133 24.195 15.8235 24.195C17.1338 24.195 18.3903 24.7187 19.3169 25.651L25.7059 32.0811M40.5294 19.027V32.0811M40.5294 32.0811L36.6111 28.1375C35.6844 27.2052 34.4279 26.6815 33.1176 26.6815C31.8074 26.6815 30.5508 27.2052 29.6242 28.1375L25.7059 32.0811M25.7059 32.0811L30.6471 37.0541M33.1176 5.97297H43M38.0588 1V10.9459M25.7059 17.1622H25.7306"
                  stroke="#99ACC2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-footer">
      <button class="bb-btn btn-success" @click="submit()">
        {{ $t("placeImagesModal.saveBtn") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import httpService from "@/core/services/http.service.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";

export default {
  name: "PlaceImagesModal",
  props: {
    /**
     * Place object
     * @type {Object}
     */
    place: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formDataIndex: 0,
      formData: new FormData(),
      uploadedImages: []
    };
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
    },

    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#place_images_modal_close_btn").click();
    },

    /**
     * Reset component state
     * @return {void}
     */
    reset() {
      this.uploadedImages = [];
      this.formData = new FormData();
      this.formDataIndex = 0;
    },

    /**
     * Activate imageInput
     * @return {void}
     */
    uploadImage() {
      this.$refs.imageInput.click();
    },

    /**
     * Handle image upload
     * @param {Object} event
     * @return {void}
     */
    onFileChange(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.formData.append(
          "images[" + this.formDataIndex + "]",
          event.target.files[i]
        );
        this.uploadedImages.push({
          url: URL.createObjectURL(event.target.files[i])
        });
        this.formDataIndex++;
      }
      // event.target.files.forEach(image => {
      //   this.formData.append("images[" + this.formDataIndex + "]", image);
      //   this.uploadedImages.push({ url: URL.createObjectURL(image) });
      //   this.formDataIndex++;
      // });
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      httpService
        .post("/api/b2b/place/" + this.place.id + "/images", this.formData)
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.closeModal();
          this.$store.dispatch(GET_PLACES);
        })
        .catch(error => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/place-images-modal.scss";
</style>

const placeForm = {
  /**
   * Place form key
   * @type {String}
   */
  key: "placeForm",

  /**
   * Place form fields object
   * @type {Object}
   */
  fields: {
    logo: null,
    name: "",
    publicProfile: "",
    phone: "",
    email: "",
    description: "",
    mainCategory: null,
    additionalCategories: [],
    address: "",
    latitude: "",
    longitude: "",
    maxReservations: ""
  },

  /**
   * Place form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Place form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Place form path
   * @type {String}
   */
  path: "/api/b2b/places",

  /**
   * Place form helpers
   * @type {Object}
   */
  helpers: {}
};

export default placeForm;

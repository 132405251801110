const companyForm = {
  /**
   * Company form key
   * @type {String}
   */
  key: "companyForm",

  /**
   * Company form fields object
   * @type {Object}
   */
  fields: {
    name: null,
    organisationNumber: null,
    address: null,
    postalNumber: null,
    city: null,
    email: null,
    website: null,
    facebookPage: null,
    instagramPage: null,
  },

  /**
   * Company form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Company form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Company form path
   * @type {String}
   */
  path: "/api/b2b/company",

  /**
   * Company form helpers
   * @type {Object}
   */
  helpers: {}
};

export default companyForm;

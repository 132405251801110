<template>
  <div id="location_input">
    <div class="bb-card">
      <div class="card-header">
        <span class="card-title">{{ $t("locationInput.title") }}</span>
      </div>
      <div class="card-body">
        <p class="location-details-label">{{ $t("locationInput.label") }}</p>
        <div class="bb-input-group location-input-group">
          <div class="input-icon">
            <svg
              width="13"
              height="16"
              viewBox="0 0 13 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.22217 0.666748C9.46928 0.666748 12.2222 3.14675 12.2222 6.66675C12.2222 7.6623 11.8204 8.8143 11.0968 10.1094C10.6462 10.9147 10.0782 11.7601 9.42039 12.6276C8.57522 13.7379 7.66581 14.7978 6.69683 15.8019C6.63481 15.8647 6.56091 15.9147 6.47943 15.9488C6.39794 15.9829 6.31049 16.0004 6.22217 16.0004C6.13384 16.0004 6.04639 15.9829 5.96491 15.9488C5.88342 15.9147 5.80952 15.8647 5.7475 15.8019C5.45687 15.501 5.17178 15.1949 4.89239 14.8836C4.23904 14.1575 3.6157 13.4048 3.02395 12.6276C2.36617 11.7601 1.79817 10.9147 1.3475 10.1094C0.623946 8.8143 0.222168 7.66319 0.222168 6.66675C0.222168 3.14675 2.97506 0.666748 6.22217 0.666748ZM6.22217 2.00008C3.68172 2.00008 1.5555 3.91564 1.5555 6.66675C1.5555 7.38941 1.88883 8.34497 2.51195 9.45786C2.92972 10.2045 3.46306 11.0001 4.08706 11.8223C4.75751 12.7035 5.47004 13.5519 6.22217 14.3645L6.55995 13.9939C7.17328 13.3094 7.78661 12.5761 8.35728 11.8223C8.98128 11.0001 9.5155 10.2045 9.93239 9.45786C10.5555 8.34497 10.8888 7.38941 10.8888 6.66675C10.8888 3.91564 8.76261 2.00008 6.22217 2.00008ZM6.22217 4.00008C6.92941 4.00008 7.60769 4.28103 8.10779 4.78113C8.60788 5.28123 8.88883 5.9595 8.88883 6.66675C8.88883 7.37399 8.60788 8.05227 8.10779 8.55237C7.60769 9.05246 6.92941 9.33342 6.22217 9.33342C5.51492 9.33342 4.83665 9.05246 4.33655 8.55237C3.83645 8.05227 3.5555 7.37399 3.5555 6.66675C3.5555 5.9595 3.83645 5.28123 4.33655 4.78113C4.83665 4.28103 5.51492 4.00008 6.22217 4.00008ZM6.22217 5.33341C5.86855 5.33341 5.52941 5.47389 5.27936 5.72394C5.02931 5.97399 4.88883 6.31313 4.88883 6.66675C4.88883 7.02037 5.02931 7.35951 5.27936 7.60956C5.52941 7.85961 5.86855 8.00008 6.22217 8.00008C6.57579 8.00008 6.91493 7.85961 7.16498 7.60956C7.41503 7.35951 7.5555 7.02037 7.5555 6.66675C7.5555 6.31313 7.41503 5.97399 7.16498 5.72394C6.91493 5.47389 6.57579 5.33341 6.22217 5.33341Z"
                fill="#253342"
              />
            </svg>
          </div>
          <input type="text" v-model="addressString" />
          <div
            class="location-options-container"
            v-if="locationOptions.length > 0"
          >
            <div
              class="location-option-item"
              @click="updateLocation(location)"
              v-for="(location, index) in locationOptions"
              :key="index"
            >
              {{ location.address }}
            </div>
          </div>
        </div>
        <l-map
          :options="mapData.mapOptions"
          :zoom="mapData.zoom"
          :center="mapData.center"
          style="height: 243px;"
          @update:center="mapCenterUpdate"
          @update:zoom="mapZoomUpdate"
          ref="myMap"
        >
          <l-tile-layer :url="mapData.url" />
          <l-marker :lat-lng="mapData.withTooltip" :icon="mapData.icon">
            <l-tooltip
              :options="{
                permanent: true,
                interactive: true,
                direction: 'top'
              }"
              >{{ mapData.tooltipText }}</l-tooltip
            >
          </l-marker>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import axios from "axios";

export default {
  name: "LocationInput",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  props: {
    /**
     * Latitude value
     * @type {String|Number}
     */
    latitude: {
      type: [String, Number],
      required: false,
      default: null
    },

    /**
     * Longitude value
     * @type {String|Number}
     */
    longitude: {
      type: [String, Number],
      required: false,
      default: null
    },

    /**
     * Location name
     * @type {String}
     */
    locationName: {
      type: String,
      required: false,
      default: ""
    },

    /**
     * Address string value
     * @type {String}
     */
    address: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      addressString: this.address,
      currentZoom: 11.5,
      currentCenter: latLng(this.latitude, this.longitude),
      awaitingSearch: false,
      locationOptions: []
    };
  },
  methods: {
    /**
     * Update map zoom
     * @param {Number} zoom
     * @return {void}
     */
    mapZoomUpdate(zoom) {
      this.currentZoom = zoom;
    },

    /**
     * Update map center
     * @param center
     * @return {void}
     */
    mapCenterUpdate(center) {
      this.currentCenter = center;
    },

    /**
     * Emit updateLocation event with selected location object
     * @param {Object} location
     * @emit updateLocation
     */
    updateLocation(location) {
      this.$emit("updateLocation", location);
      this.locationOptions = [];
    }
  },
  computed: {
    /**
     * Return mapData object
     * @return {Object}
     */
    mapData() {
      return {
        zoom: 13,
        center: latLng(this.latitude, this.longitude),
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withTooltip: latLng(this.latitude, this.longitude),
        currentZoom: this.currentZoom,
        currentCenter: this.currentCenter,
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5
        },
        showMap: true,
        tooltipText: this.locationName,
        icon: icon({
          iconUrl: "http://leafletjs.com/examples/custom-icons/leaf-green.png",
          iconSize: [32, 37],
          iconAnchor: [16, 37]
        })
      };
    }
  },
  watch: {
    /**
     * Watch for addressString change
     */
    addressString() {
      if (!this.awaitingSearch && this.addressString !== this.address) {
        setTimeout(() => {
          let url =
            "https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&limit=5&q=" +
            this.addressString;
          axios.get(url).then(response => {
            response.data.forEach(location => {
              this.locationOptions = [];
              let addressString = "";
              let cityString = location.address.city
                ? location.address.city
                : location.address.county;
              if (location.address.house_number) {
                addressString =
                  location.address.road +
                  " " +
                  location.address.house_number +
                  ", " +
                  location.address.state +
                  ", " +
                  cityString;
              } else {
                addressString =
                  location.address.road +
                  ", " +
                  location.address.state +
                  ", " +
                  cityString;
              }
              let locationObject = {
                address: addressString,
                latitude: location.lat,
                longitude: location.lon
              };
              this.locationOptions.push(locationObject);
            });
          });
          this.awaitingSearch = false;
        }, 1500);
      }
      this.awaitingSearch = true;
    },
    /**
     * Watch for address prop change
     */
    address() {
      this.addressString = this.address;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/location-input.scss";
</style>

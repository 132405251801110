import placeForm from "@/core/services/forms/placeForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";
import { OPEN_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { disableBodyScroll } from "body-scroll-lock";
// import _ from "lodash";

export default {
  methods: {
    /**
     * Init place form with default values from placeForm object and open place form modal element
     * @param {Object|null} fields
     * @return {void}
     */
    createPlace(fields = null) {
      let form = JSON.parse(JSON.stringify(placeForm));
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          form.fields[key] = value;
        }
      }
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.placeFormModal) {
        this.$refs.placeFormModal.show("createPlace");
      }
    },

    /**
     * Init place form with basic info fields only and open place form modal element
     * @param {Object} place
     * @return {void}
     */
    editBasicInfo(place) {
      let form = JSON.parse(JSON.stringify(placeForm));
      form.mode = "edit";
      form.path = form.path + "/" + place.id;
      form.fields = {
        _method: "PUT",
        logo: null,
        name: place.name,
        publicProfile: place.publicProfile ? place.publicProfile : "",
        phone: place.phone,
        email: place.email,
        description: place.description,
        maxReservations: place.maxReservations
      };
      if (place.logo) {
        form.helpers.logo = place.logo;
      }
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.placeFormModal) {
        this.$refs.placeFormModal.show("editBasicInfo");
      }
    },

    /**
     * Init place form with main and additional categories only and open place form modal element
     * @param {Object} place
     * @return {void}
     */
    editCategories(place) {
      let form = JSON.parse(JSON.stringify(placeForm));
      form.mode = "edit";
      form.path = form.path + "/" + place.id;
      form.fields = {
        _method: "PUT",
        mainCategory: place.mainCategory ? place.mainCategory.id : null,
        additionalCategories: []
      };
      if (place.additionalCategories) {
        place.additionalCategories.forEach(category => {
          form.fields.additionalCategories.push(category.id);
        });
      }
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.placeFormModal) {
        this.$refs.placeFormModal.show("editCategories");
      }
    },

    /**
     * Init place form with opening hours only and open opening hours form element
     * @param {Object} place
     * @return {void}
     */
    editOpeningHours(place) {
      let form = JSON.parse(JSON.stringify(placeForm));
      form.mode = "edit";
      form.path = form.path + "/" + place.id;
      form.fields = {
        _method: "PUT",
        openingHours: place.openingHours
      };
      form.helpers = {
        openingHours: this.generateOpeningHoursHelper(place.openingHours)
      };
      this.$store.dispatch(INIT_FORM, form);
      document.querySelector("#opening_hours_form_toggle").click();
      this.$store.dispatch(OPEN_ELEMENT, "opening_hours_form");
      let targetElement = document.querySelector("#opening_hours_form");
      disableBodyScroll(targetElement);
    },

    /**
     * Generate opening hours helper object
     * @param {Object} openingHours
     * @return {Object}
     */
    generateOpeningHoursHelper(openingHours) {
      let openingHoursHelper = {
        monday: {
          on: true,
          from: "06:00",
          to: "23:55"
        },
        tuesday: {
          on: true,
          from: "06:00",
          to: "23:55"
        },
        wednesday: {
          on: true,
          from: "06:00",
          to: "23:55"
        },
        thursday: {
          on: true,
          from: "06:00",
          to: "23:55"
        },
        friday: {
          on: true,
          from: "06:00",
          to: "23:55"
        },
        saturday: {
          on: false,
          from: null,
          to: null
        },
        sunday: {
          on: false,
          from: null,
          to: null
        }
      };
      for (const [key, value] of Object.entries(openingHours)) {
        if (value.from) {
          openingHoursHelper[key].on = true;
        } else {
          openingHoursHelper[key].on = false;
        }
        openingHoursHelper[key].from = value.from;
        openingHoursHelper[key].to = value.to;
      }
      return openingHoursHelper;
    }
  }
};

<template>
  <div id="account_settings_tab">
    <div class="d-flex justify-content-between align-items-top">
      <div class="bb-settings-guide">
        <div class="settings-business-info-guide">
          <h4>{{ $t("accountTab.guide.businessInfo.title") }}</h4>
          <p class="settings-guide-description">
            {{ $t("accountTab.guide.businessInfo.description") }}
          </p>
        </div>
        <div class="settings-online-links-guide">
          <h4>{{ $t("accountTab.guide.onlineLinks.title") }}</h4>
          <p class="settings-guide-description">
            {{ $t("accountTab.guide.onlineLinks.description") }}
          </p>
        </div>
      </div>
      <div class="cards-holder">
        <div class="bb-card bb-settings-card mb-12 info-card">
          <div class="card-header">
            <span class="card-title">{{
              $t("accountTab.companyDetailsCard.title")
            }}</span>
          </div>
          <div class="card-body">
            <div class="settings-business-info-mobile-guide">
              <h4>{{ $t("accountTab.guide.businessInfo.title") }}</h4>
              <p class="settings-guide-description">
                {{ $t("accountTab.guide.businessInfo.description") }}
              </p>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("accountTab.companyDetailsCard.nameInput.label")
              }}</label>
              <input class="bb-input" type="text" v-model="form.fields.name" />
              <div class="bb-invalid-feedback" v-show="form.errors.name">
                {{ form.errors.name }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t(
                  "accountTab.companyDetailsCard.organisationNumberInput.label"
                )
              }}</label>
              <input
                class="bb-input"
                type="text"
                v-model="form.fields.organisationNumber"
              />
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.organisationNumber"
              >
                {{ form.errors.organisationNumber }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("accountTab.companyDetailsCard.addressInput.label")
              }}</label>
              <input
                class="bb-input"
                type="text"
                v-model="form.fields.address"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.address">
                {{ form.errors.address }}
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center postal-city-inputs"
            >
              <div class="bb-form-group mr-2" style="flex: 1">
                <label>{{
                  $t("accountTab.companyDetailsCard.postalNumberInput.label")
                }}</label>
                <input
                  class="bb-input"
                  type="text"
                  v-model="form.fields.postalNumber"
                />
                <div
                  class="bb-invalid-feedback"
                  v-show="form.errors.postalNumber"
                >
                  {{ form.errors.postalNumber }}
                </div>
              </div>
              <div class="bb-form-group ml-2" style="flex: 2">
                <label>{{
                  $t("accountTab.companyDetailsCard.cityInput.label")
                }}</label>
                <input
                  class="bb-input"
                  type="text"
                  v-model="form.fields.city"
                />
                <div class="bb-invalid-feedback" v-show="form.errors.city">
                  {{ form.errors.city }}
                </div>
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("accountTab.companyDetailsCard.emailInput.label")
              }}</label>
              <input class="bb-input" type="text" v-model="form.fields.email" />
              <div class="bb-invalid-feedback" v-show="form.errors.email">
                {{ form.errors.email }}
              </div>
            </div>
          </div>
        </div>
        <div class="bb-card bb-settings-card mb-12 links-card">
          <div class="card-body">
            <div class="settings-business-info-mobile-guide">
              <h4>{{ $t("accountTab.guide.onlineLinks.title") }}</h4>
              <p class="settings-guide-description">
                {{ $t("accountTab.guide.onlineLinks.description") }}
              </p>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("accountTab.onlineLinksCard.websiteInput.label")
              }}</label>
              <div class="bb-input-group">
                <div class="input-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#67768C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-globe"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path
                      d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                    ></path>
                  </svg>
                </div>
                <input type="text" v-model="form.fields.website" />
              </div>
              <div class="bb-invalid-feedback" v-show="form.errors.website">
                {{ form.errors.website }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("accountTab.onlineLinksCard.fbPageInput.label")
              }}</label>
              <div class="bb-input-group">
                <div class="input-icon">
                  <i class="fab fa-facebook-square"></i>
                </div>
                <input type="text" v-model="form.fields.facebookPage" />
              </div>
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.facebookPage"
              >
                {{ form.errors.facebookPage }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("accountTab.onlineLinksCard.igPageInput.label")
              }}</label>
              <div class="bb-input-group">
                <div class="input-icon">
                  <i class="fab fa-instagram"></i>
                </div>
                <input type="text" v-model="form.fields.instagramPage" />
              </div>
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.instagramPage"
              >
                {{ form.errors.instagramPage }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end pt-6 save-btn-container">
          <button
            class="bb-btn btn-success"
            style="min-width: 130px"
            @click="submit()"
          >
            {{ $t("accountTab.saveBtn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/core/mixins/formMixin.js";
import companyForm from "@/core/services/forms/companyForm.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_COMPANY } from "@/core/services/store/company.module.js";
import { mapGetters } from "vuex";
import companyFormMixin from "@/core/mixins/companyFormMixin.js";

export default {
  name: "AccountSettingsTab",
  mixins: [formMixin, companyFormMixin],
  beforeMount() {
    this.form = JSON.parse(JSON.stringify(companyForm));
    // Dispatch store GET_COMPANY action to get authenticated user company from api
    this.$store.dispatch(GET_COMPANY);
  },
  methods: {
    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.submitForm()
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.$store.dispatch(GET_COMPANY);
        })
        .catch((error) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          });
        });
    },
  },
  computed: {
    ...mapGetters(["myCompany"]),
  },
  watch: {
    /**
     * Watch for myCompany property
     */
    myCompany: {
      immediate: true,
      deep: true,
      handler() {
        if (this.myCompany) {
          this.editCompany(this.myCompany);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/account-settings-tab.scss";
</style>

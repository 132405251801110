import companyForm from "@/core/services/forms/companyForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";

export default {
  methods: {
    /**
     * Init company form with edit mode
     * @param {Object} company
     * @return {void}
     */
    editCompany(company) {
      let form = JSON.parse(JSON.stringify(companyForm));
      form.mode = "edit";
      for (const [key, value] of Object.entries(company)) {
        form.fields[key] = value;
      }
      form.fields._method = "PUT";
      this.$store.dispatch(INIT_FORM, form);
    }
  }
};

<template>
  <div class="bb-card categories-card" id="categories_input">
    <div class="categories-container">
      <div
        class="single-category-card"
        :class="additionalCategoryClasses(category.id)"
        @click="handleCategorySelect(category.id)"
        v-for="category in allCategories"
        :key="category.id"
      >
        <div
          class="disabled-category-overlay"
          v-show="isCategoryDisabled(category.id)"
        ></div>
        <div
          class="selected-category-badge"
          v-if="isCategorySelected(category.id)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </div>
        <div
          class="category-image"
          :style="{ 'background-image': 'url(' + category.image.url + ')' }"
        ></div>
        <div class="category-name">
          <span>{{ category.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesInput",
  props: {
    /**
     * All categories
     * @type {Array}
     */
    allCategories: {
      type: Array,
      required: true
    },

    /**
     * Determine if multiple categories can be selected
     * @type {Boolean}
     */
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Preselected categories ids
     * @type {Number|Array}
     */
    selected: {
      type: [Number, Array],
      required: false
    },

    /**
     * Categories ids that are disabled
     * @type {Array}
     */
    disabled: {
      type: Array,
      required: false,
      default: null
    }
  },
  data() {
    return {
      selectedCategoryId: null,
      selectedCategoriesIds: []
    };
  },
  methods: {
    /**
     * Check if category is selected by id
     * @param {Number} categoryId
     * @return {Boolean}
     */
    isCategorySelected(categoryId) {
      if (this.multiple) {
        return this.selectedCategoriesIds.includes(categoryId);
      } else {
        return this.selectedCategoryId === categoryId;
      }
    },

    /**
     * Check if category is disabled by id
     * @param {Number} categoryId
     * @return {Boolean}
     */
    isCategoryDisabled(categoryId) {
      if (this.disabled) {
        return this.disabled.includes(categoryId);
      }
      return false;
    },

    /**
     * Return string of additional classes for category card by category id
     * @param {Number} categoryId
     * @return {String}
     */
    additionalCategoryClasses(categoryId) {
      if (this.isCategoryDisabled(categoryId)) {
        return "disabled";
      }
      if (this.isCategorySelected(categoryId)) {
        return "selected";
      }
      return "";
    },

    /**
     * Handle category select
     * @param {Number} categoryId
     * @emit valueUpdated
     */
    handleCategorySelect(categoryId) {
      if (this.isCategoryDisabled(categoryId)) {
        return;
      }
      if (this.multiple) {
        if (this.selectedCategoriesIds.includes(categoryId)) {
          let categoryIndex = this.selectedCategoriesIds.findIndex(
            id => id === categoryId
          );
          this.selectedCategoriesIds.splice(categoryIndex, 1);
        } else {
          this.selectedCategoriesIds.push(categoryId);
        }
        this.$emit("valueUpdated", this.selectedCategoriesIds);
      } else {
        this.selectedCategoryId = categoryId;
        this.$emit("valueUpdated", this.selectedCategoryId);
      }
    }
  },
  watch: {
    /**
     * Watch for selected prop
     */
    selected: {
      immediate: true,
      handler() {
        if (this.selected) {
          if (this.multiple) {
            if (Array.isArray(this.selected)) {
              this.selectedCategoriesIds = this.selected;
            }
          } else {
            if (!Array.isArray(this.selected)) {
              this.selectedCategoryId = this.selected;
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/categories-input.scss";
</style>

import moment from "moment";
import _ from "lodash";

const openingHoursHelper = {
  /**
   * Return array of weekdays starting from Monday
   * @return {Array}
   */
  weekdays: function() {
    moment.locale("en");
    let weekdays = moment.weekdays();
    let sunday = weekdays.splice(0, 1);
    weekdays.push(sunday.toString());
    return weekdays;
  },

  /**
   * Format openingHours by grouping those with same time
   * @param {Object} openingHours
   * @param {String} closedString
   * @param {Object} weekdaysNames
   * @return {Array}
   */
  format: function(openingHours, closedString = "Closed", weekdaysNames = {}) {
    let openingHoursArray = [];
    this.weekdays().forEach(day => {
      let time = openingHours[day.toLowerCase()];
      let openingHoursItem = {
        day: day,
        time: time.from && time.to ? time.from + " - " + time.to : closedString
      };
      openingHoursArray.push(openingHoursItem);
    });

    let formattedOpeningHoursArray = [];
    openingHoursArray.forEach(openingHoursItem => {
      if (formattedOpeningHoursArray.length === 0) {
        formattedOpeningHoursArray.push(openingHoursItem);
      } else {
        let lastPushedItem =
          formattedOpeningHoursArray[formattedOpeningHoursArray.length - 1];
        if (lastPushedItem.time === openingHoursItem.time) {
          lastPushedItem.day =
            lastPushedItem.day.split(" ")[0] + " - " + openingHoursItem.day;
        } else {
          formattedOpeningHoursArray.push(openingHoursItem);
        }
      }
    });

    //check if weekdaysNames is not empty object
    if (_.values(weekdaysNames).some(x => x !== undefined)) {
      // if weekdaysNames is not empty translate day property for each item of formatted array
      formattedOpeningHoursArray.forEach(item => {
        let dayStringArray = item.day.split(" - ");
        if (dayStringArray.length > 1) {
          item.day =
            weekdaysNames[dayStringArray[0].toLowerCase()] +
            " - " +
            weekdaysNames[dayStringArray[1].toLowerCase()];
        } else {
          item.day = weekdaysNames[dayStringArray[0].toLowerCase()];
        }
      });
    }

    return formattedOpeningHoursArray;
  }
};

export default openingHoursHelper;

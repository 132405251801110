<template>
  <div id="locations_tab">
    <div v-if="!selectedPlace">
      <div
        class="d-flex justify-content-between align-items-baseline heading-container"
      >
        <div class="heading">
          <h2>{{ $t("locationsTab.allLocationsSection.title") }}</h2>
          <p>{{ $t("locationsTab.allLocationsSection.description") }}</p>
        </div>
        <button
          class="bb-btn btn-success add-location-btn"
          @click="createPlace()"
        >
          {{ $t("locationsTab.allLocationsSection.createPlaceBtn") }}
        </button>
      </div>
      <div class="locations-list mt-3">
        <div
          class="bb-card location-list-item"
          v-for="place in allPlaces"
          :key="place.id"
          @click="selectPlace(place)"
        >
          <div class="location-item-details">
            <div class="location-logo-holder">
              <div class="location-logo-placeholder" v-if="!place.logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="39"
                  height="39"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-image"
                >
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                  <polyline points="21 15 16 10 5 21"></polyline>
                </svg>
              </div>
              <img class="location-logo" :src="place.logo" />
            </div>
            <div class="location-info">
              <p class="location-name">{{ place.name }}</p>
              <span class="location-address">{{ place.address }}</span>
            </div>
          </div>
          <div class="location-actions">
            <button class="see-location-btn">
              <i class="la la-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
      <button class="mobile-new-place-btn" @click="createPlace()">
        <i class="la la-plus"></i>
      </button>
    </div>
    <div v-if="selectedPlace">
      <div
        class="d-flex justify-content-between align-items-baseline mb-10 selected-place-heading-section"
      >
        <div class="heading">
          <h2>
            <button @click="unsetSelectedPlace()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </button>
            {{ selectedPlace.name }}
          </h2>
        </div>
        <BBDropdown
          positionClass="center"
          class="selected-location-options-dropdown"
        >
          <template v-slot:toggle>
            <div class="selected-location-options-toggle">
              <span>{{
                $t("locationsTab.selectedPlaceSection.dropdown.toggleBtn")
              }}</span>
              <i class="la la-angle-down"></i>
            </div>
          </template>
          <template v-slot:items>
            <div class="action-item" @click="editBasicInfo(selectedPlace)">
              <span>{{
                $t("locationsTab.selectedPlaceSection.dropdown.editBtn")
              }}</span>
            </div>
            <div class="action-item danger" @click="deletePlace()">
              <span>{{
                $t("locationsTab.selectedPlaceSection.dropdown.deleteBtn")
              }}</span>
            </div>
          </template>
        </BBDropdown>
      </div>
      <div
        class="d-flex justify-content-between alig-items-center mb-4 selected-place-cards-holder"
      >
        <div class="bb-card mr-2 location-details-card">
          <div class="card-header">
            <span class="card-title">{{
              $t("locationsTab.selectedPlaceSection.detailsCard.title")
            }}</span>
            <button
              class="card-header-action-btn"
              @click="editBasicInfo(selectedPlace)"
            >
              {{ $t("locationsTab.selectedPlaceSection.detailsCard.editBtn") }}
            </button>
          </div>
          <div class="card-body">
            <div class="location-details-item">
              <p class="location-details-label">
                {{
                  $t("locationsTab.selectedPlaceSection.detailsCard.emailLabel")
                }}
              </p>
              <button class="location-details-action-btn">
                {{ selectedPlace.email }}
              </button>
            </div>
            <div class="location-details-item">
              <p class="location-details-label">
                {{
                  $t("locationsTab.selectedPlaceSection.detailsCard.phoneLabel")
                }}
              </p>
              <button class="location-details-action-btn">
                {{ selectedPlace.phone }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="!currentUser.isInLawIndustry" class="bb-card ml-2">
          <div class="card-header">
            <span class="card-title">{{
              $t("locationsTab.selectedPlaceSection.categoryCard.title")
            }}</span>
            <button
              class="card-header-action-btn"
              @click="editCategories(selectedPlace)"
            >
              {{ $t("locationsTab.selectedPlaceSection.categoryCard.editBtn") }}
            </button>
          </div>
          <div class="card-body">
            <div class="location-details-item">
              <p class="location-details-label">
                {{
                  $t(
                    "locationsTab.selectedPlaceSection.categoryCard.mainCategoryLabel"
                  )
                }}
              </p>
              <span
                v-if="selectedPlace.mainCategory"
                class="location-details-text"
              >
                {{ selectedPlace.mainCategory.name }}
              </span>
              <button
                class="location-details-action-btn"
                v-if="!selectedPlace.mainCategory"
              >
                {{
                  $t("locationsTab.selectedPlaceSection.categoryCard.addBtn")
                }}
              </button>
            </div>
            <div class="location-details-item">
              <p class="location-details-label">
                {{
                  $t(
                    "locationsTab.selectedPlaceSection.categoryCard.additionalCategoryLabel"
                  )
                }}
              </p>
              <span
                class="location-details-text"
                v-if="selectedPlace.additionalCategories.length > 0"
              >
                {{ additionalCategoriesString }}
              </span>
              <button
                class="location-details-action-btn"
                v-if="selectedPlace.additionalCategories.length < 1"
              >
                {{
                  $t("locationsTab.selectedPlaceSection.categoryCard.addBtn")
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between alig-items-center mb-4 selected-place-cards-holder"
      >
        <div class="bb-card mr-2">
          <div class="card-header">
            <span class="card-title">{{
              $t("locationsTab.selectedPlaceSection.openingHoursCard.title")
            }}</span>
            <button
              class="card-header-action-btn"
              @click="editOpeningHours(selectedPlace)"
            >
              {{
                $t("locationsTab.selectedPlaceSection.openingHoursCard.editBtn")
              }}
            </button>
          </div>
          <div class="card-body">
            <!-- -->
            <div
              class="opening-hours-item"
              v-for="(openingHoursItem, index) in openingHours"
              :key="index"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="opening-hours-item-badge">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.74998 0C3.9175 0 0 3.9175 0 8.74998C0 13.5825 3.9175 17.5 8.74998 17.5C13.5825 17.5 17.5 13.5825 17.5 8.74998C17.4948 3.91966 13.5803 0.00516357 8.74998 0ZM9.37499 8.74998C9.37499 9.09517 9.09517 9.37499 8.74998 9.37499H3.75C3.40481 9.37499 3.12499 9.09517 3.12499 8.74998C3.12499 8.40479 3.40481 8.12497 3.75 8.12497H8.12501V3.75C8.12501 3.40481 8.40483 3.12499 8.75002 3.12499C9.09521 3.12499 9.37499 3.40481 9.37499 3.75V8.74998Z"
                        fill="#CBD6E2"
                      />
                    </svg>
                  </div>
                  <div class="opening-hours-item-text">
                    <span class="opening-hours-item-days">{{
                      openingHoursItem.day
                    }}</span>
                    <span class="opening-hours-item-time">{{
                      openingHoursItem.time
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- -->
          </div>
        </div>
        <div class="bb-card ml-2 location-photos-card">
          <div class="card-header">
            <span class="card-title">{{
              $t("locationsTab.selectedPlaceSection.photosCard.title")
            }}</span>
            <button class="card-header-action-btn" @click="openImagesModal()">
              {{ $t("locationsTab.selectedPlaceSection.photosCard.editBtn") }}
            </button>
          </div>
          <div class="card-body">
            <div class="location-images-container">
              <div
                class="location-image"
                v-for="image in selectedPlace.images"
                :key="image.id"
              >
                <img :src="image.url" />
              </div>
              <div
                class="location-image"
                v-show="selectedPlace.images.length === 0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-image"
                >
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                  <polyline points="21 15 16 10 5 21"></polyline>
                </svg>
              </div>
            </div>
            <div class="location-images-description">
              <p>
                {{ $t("locationsTab.selectedPlaceSection.photosCard.label") }}
              </p>
              <span>{{
                $t("locationsTab.selectedPlaceSection.photosCard.description")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <LocationInput
        :latitude="selectedPlace.latitude"
        :longitude="selectedPlace.longitude"
        :locationName="selectedPlace.name"
        :address="selectedPlace.address"
        @updateLocation="updatePlaceLocation($event)"
      />
      <PlaceImagesModal ref="placeImagesModal" :place="selectedPlace" />
      <div
        id="mobile_selected_place_actions_overlay"
        v-show="mobileActionsVisible"
      >
        <div
          class="mobile-selected-place-actions-container"
          v-click-outside="handleMobileActionsOutsideClick"
        >
          <div class="mobile-selected-place-actions">
            <button
              class="mobile-selected-place-action-btn"
              @click="
                editBasicInfo(selectedPlace);
                hideMobileActions();
              "
            >
              {{
                $t("locationsTab.selectedPlaceSection.mobileButtons.editBtn")
              }}
            </button>
            <button
              class="mobile-selected-place-action-btn danger"
              @click="
                hideMobileActions();
                deletePlace();
              "
            >
              {{
                $t("locationsTab.selectedPlaceSection.mobileButtons.deleteBtn")
              }}
            </button>
          </div>
          <button
            class="mobile-selected-place-action-btn"
            @click="hideMobileActions()"
          >
            {{ $t("locationsTab.selectedPlaceSection.mobileButtons.closeBtn") }}
          </button>
        </div>
      </div>
    </div>
    <PlaceFormModal ref="placeFormModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import BBDropdown from "@/view/content/BBDropdown.vue";
import openingHoursHelper from "@/core/services/helpers/openingHoursHelper.js";
import LocationInput from "@/view/pages/components/Place/LocationInput.vue";
import PlaceFormModal from "@/view/layout/extras/modal/PlaceFormModal.vue";
import PlaceImagesModal from "@/view/layout/extras/modal/PlaceImagesModal.vue";
import httpService from "@/core/services/http.service.js";
import placeMapper from "@/core/services//helpers/placeMapper.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import placeFormMixin from "@/core/mixins/placeFormMixin.js";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
//import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "LocationsTab",
  components: {
    BBDropdown,
    PlaceFormModal,
    LocationInput,
    PlaceImagesModal
  },
  mixins: [placeFormMixin],
  data() {
    return {
      selectedPlace: null,
      imagesNumber: [1, 2, 3, 4, 5, 6, 7],
      mobileActionsVisible: false
    };
  },
  beforeMount() {
    // Dispatch store GET_PLACES action to get places from api
    this.$store.dispatch(GET_PLACES);
  },
  methods: {
    /**
     * Handle mobile actions outside click
     * @param {Object} event
     * @return {void}
     */
    handleMobileActionsOutsideClick(event) {
      if (event.target.id === "mobile_selected_place_actions_overlay") {
        this.hideMobileActions();
      }
    },

    /**
     * Set selectedPlace data property
     * @param {Object} place
     * @return {void}
     */
    selectPlace(place) {
      this.selectedPlace = place;
      this.$emit("placeSelected", this.selectedPlace);
    },

    /**
     * Set selectedPlace data property to null
     * @return {void}
     */
    unsetSelectedPlace() {
      this.selectedPlace = null;
    },

    /**
     * Open placeImagesModal
     * @return {void}
     */
    openImagesModal() {
      this.$refs.placeImagesModal.show();
    },

    /**
     * Update location for selected place
     * @param {Object} location
     * @return {void}
     */
    updatePlaceLocation(location) {
      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("address", location.address);
      formData.append("latitude", location.latitude);
      formData.append("longitude", location.longitude);
      httpService
        .post("/api/b2b/places/" + this.selectedPlace.id, formData)
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.selectedPlace = placeMapper.mapPlace(response.data.data);
          let placeIndex = this.allPlaces.findIndex(
            place => place.id === this.selectedPlace.id
          );
          this.allPlaces[placeIndex] = this.selectedPlace;
        })
        .catch(error => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          });
        });
    },

    /**
     * Delete selected place
     * @return {void}
     */
    deletePlace() {
      if (this.selectedPlace) {
        httpService
          .delete("/api/b2b/places/" + this.selectedPlace.id)
          .then(response => {
            this.$store.dispatch(SET_ALERT_MESSAGES, {
              successMessages: [response.data.message],
              errorMessages: null
            });
            this.selectedPlace = null;
            this.$store.dispatch(GET_PLACES);
          })
          .catch(error => {
            this.$store.dispatch(SET_ALERT_MESSAGES, {
              successMessages: null,
              errorMessages: [error.response.data.message]
            });
          });
      }

      return;
    },

    /**
     * Show mobile actions for selected place
     * @return {void}
     */
    showMobileActions() {
      this.mobileActionsVisible = true;
      let targetElement = document.querySelector(
        "#mobile_selected_place_actions_overlay"
      );
      disableBodyScroll(targetElement);
    },

    /**
     * Hide mobile actions for selected place
     * @return {void}
     */
    hideMobileActions() {
      this.mobileActionsVisible = false;
      let targetElement = document.querySelector(
        "#mobile_selected_place_actions_overlay"
      );
      enableBodyScroll(targetElement);
    }
  },
  computed: {
    ...mapGetters(["allPlaces", "currentUser"]),

    /**
     * Return array for presentation of working hours
     * @return {Array}
     */
    openingHours() {
      if (this.selectedPlace) {
        return openingHoursHelper.format(
          this.selectedPlace.openingHours,
          this.$t(
            "locationsTab.selectedPlaceSection.openingHoursCard.closedLabel"
          ),
          this.$t("weekdays")
        );
      }

      return [];
    },

    /**
     * Return string presentation of selectedPlace additionalCategories
     * @return {String}
     */
    additionalCategoriesString() {
      if (
        this.selectedPlace &&
        this.selectedPlace.additionalCategories.length > 0
      ) {
        let categoriesNames = [];
        this.selectedPlace.additionalCategories.forEach(category => {
          categoriesNames.push(category.name);
        });

        return categoriesNames.join(", ");
      }

      return "";
    }
  },

  watch: {
    /**
     * Watch for all places change
     */
    allPlaces() {
      if (this.selectedPlace) {
        this.selectedPlace = this.allPlaces.find(
          place => place.id === this.selectedPlace.id
        );
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/locations-tab.scss";
</style>

<template>
  <b-modal
    id="place_form_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
    :hide-footer="true"
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <button
          class="mobile-previous-btn"
          v-show="hasPreviousStep"
          @click="previousStep()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#253342"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </button>
        <div class="mobile-header-placeholder" v-show="!hasPreviousStep"></div>
        <div class="mobile-header-placeholder"></div>
        <div class="d-flex align-items-center">
          <button
            class="close-modal-btn mr-7"
            id="place_form_modal_close_btn"
            @click="close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
          <button
            v-show="hasPreviousStep"
            class="previous-btn ml-7"
            @click="previousStep()"
          >
            {{ $t("placeFormModal.previousStepBtn") }}
          </button>
        </div>
        <button
          v-show="isLastStep"
          class="bb-btn btn-success header-save-btn"
          @click="submit()"
        >
          {{ $t("placeFormModal.saveBtn") }}
        </button>
        <button
          v-show="!isLastStep"
          :disabled="!canGoToNextStep"
          class="bb-btn btn-success header-next-btn"
          :class="canGoToNextStep ? '' : 'btn-disabled'"
          @click="nextStep()"
        >
          {{ $t("placeFormModal.nextStepBtn") }}
        </button>
      </div>
      <div class="modal-header-title-container">
        <p class="steps-title" v-show="hasMultipleSteps">{{ stepsString }}</p>
        <h2>{{ modalTitle }}</h2>
        <p class="title-description" v-show="titleDescription">
          {{ titleDescription }}
        </p>
      </div>
    </template>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="basicInfoVisible"
    >
      <div class="bb-card basic-info-card">
        <div class="card-header">
          <span class="card-title">{{
            $t("placeFormModal.basicInfoCard.title")
          }}</span>
        </div>
        <div class="card-body">
          <div class="place-logo-container">
            <div class="place-logo-placeholder" @click="uploadLogo()">
              <svg
                width="44"
                height="48"
                viewBox="0 0 44 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!form.fields.logo && !form.helpers.logo"
              >
                <path
                  d="M25.7059 7.21622H5.94118C4.63069 7.21622 3.37389 7.74015 2.44724 8.67277C1.52059 9.60538 1 10.8703 1 12.1892V37.0541M1 37.0541V42.027C1 43.3459 1.52059 44.6108 2.44724 45.5434C3.37389 46.4761 4.63069 47 5.94118 47H35.5882C36.8987 47 38.1555 46.4761 39.0822 45.5434C40.0088 44.6108 40.5294 43.3459 40.5294 42.027V32.0811M1 37.0541L12.3301 25.651C13.2567 24.7187 14.5133 24.195 15.8235 24.195C17.1338 24.195 18.3903 24.7187 19.3169 25.651L25.7059 32.0811M40.5294 19.027V32.0811M40.5294 32.0811L36.6111 28.1375C35.6844 27.2052 34.4279 26.6815 33.1176 26.6815C31.8074 26.6815 30.5508 27.2052 29.6242 28.1375L25.7059 32.0811M25.7059 32.0811L30.6471 37.0541M33.1176 5.97297H43M38.0588 1V10.9459M25.7059 17.1622H25.7306"
                  stroke="#99ACC2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span
                class="place-logo-description"
                v-if="!form.fields.logo && !form.helpers.logo"
                >{{ $t("placeFormModal.basicInfoCard.logoInput.label") }}</span
              >
              <img
                v-if="!form.fields.logo && form.helpers.logo"
                class="logo-preview"
                :src="form.helpers.logo"
              />
              <img
                v-if="form.fields.logo"
                class="logo-preview"
                :src="logoUrl"
              />
            </div>
            <input
              type="file"
              style="display: none"
              ref="logoInput"
              @change="onFileChange"
            />
            <span class="place-logo-description">{{
              $t("placeFormModal.basicInfoCard.logoInput.description")
            }}</span>
          </div>
          <div class="bb-form-group mt-12">
            <label
              >{{ $t("placeFormModal.basicInfoCard.nameInput.label") }}
              <span class="bb-label-descrition">{{
                $t("placeFormModal.basicInfoCard.nameInput.description")
              }}</span></label
            >
            <input type="text" class="bb-input" v-model="form.fields.name" />
            <div class="bb-invalid-feedback" v-show="form.errors.name">
              {{ $t("placeFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("placeFormModal.basicInfoCard.publicProfileInput.label")
            }}</label>
            <div class="bb-input-group">
              <span>bookybook.rs/</span>
              <input type="text" v-model="form.fields.publicProfile" />
            </div>
            <div class="bb-invalid-feedback" v-show="form.errors.publicProfile">
              {{ $t("placeFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("placeFormModal.basicInfoCard.phoneInput.label")
            }}</label>
            <div class="bb-input-group">
              <span>+381</span>
              <input type="text" v-model="form.fields.phone" />
            </div>
            <div class="bb-invalid-feedback" v-show="form.errors.phone">
              {{ $t("placeFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("placeFormModal.basicInfoCard.emailInput.label")
            }}</label>
            <input type="text" class="bb-input" v-model="form.fields.email" />
            <div class="bb-invalid-feedback" v-show="form.errors.email">
              {{ $t("placeFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("placeFormModal.basicInfoCard.descriptionInput.label")
            }}</label>
            <b-form-textarea
              rows="4"
              class="bb-textarea"
              v-model="form.fields.description"
            ></b-form-textarea>
            <div class="bb-invalid-feedback" v-show="form.errors.description">
              {{ $t("placeFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label
              >{{
                $t("placeFormModal.basicInfoCard.maxReservationInput.label")
              }}
              <span class="bb-label-descrition">{{
                $t(
                  "placeFormModal.basicInfoCard.maxReservationInput.description"
                )
              }}</span>
            </label>
            <div class="bb-input-group">
              <span>{{
                $t(
                  "placeFormModal.basicInfoCard.maxReservationInput.shortDescription"
                )
              }}</span>
              <input type="text" v-model="form.fields.maxReservations" />
            </div>
            <div
              class="bb-invalid-feedback"
              v-show="form.errors.maxReservations"
            >
              {{ $t("placeFormModal.validation.error") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="mainCategoryVisible"
    >
      <CategoriesInput
        :allCategories="allCategories"
        :selected="form.fields.mainCategory"
        @valueUpdated="updateMainCategory($event)"
      />
      <div class="bb-invalid-feedback" v-show="form.errors.mainCategory">
        {{ $t("placeFormModal.validation.mainCategoryError") }}
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="additionalCategoriesVisible"
    >
      <CategoriesInput
        :allCategories="allCategories"
        :multiple="true"
        :selected="form.fields.additionalCategories"
        :disabled="[form.fields.mainCategory]"
        @valueUpdated="updateAdditionalCategories($event)"
      />
      <div
        class="bb-invalid-feedback"
        v-show="form.errors.additionalCategories"
      >
        {{ $t("placeFormModal.validation.error") }}
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="locationVisible"
    >
      <div class="location-input-container">
        <LocationInput
          :latitude="form.fields.latitude"
          :longitude="form.fields.longitude"
          :locationName="form.fields.name"
          :address="form.fields.address"
          @updateLocation="updateLocationFields($event)"
        />
        <div
          class="bb-invalid-feedback"
          v-show="
            form.errors.latitude || form.errors.longitude || form.errors.address
          "
        >
          {{ $t("placeFormModal.validation.locationError") }}
        </div>
      </div>
    </div>
    <div class="mobile-footer">
      <button v-show="isLastStep" class="bb-btn btn-success" @click="submit()">
        {{ $t("placeFormModal.saveBtn") }}
      </button>
      <button
        v-show="!isLastStep"
        :disabled="!canGoToNextStep"
        class="bb-btn btn-success"
        :class="canGoToNextStep ? '' : 'btn-disabled'"
        @click="nextStep()"
      >
        {{ $t("placeFormModal.nextStepBtn") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_CATEGORIES } from "@/core/services/store/category.module.js";
import LocationInput from "@/view/pages/components/Place/LocationInput.vue";
import CategoriesInput from "@/view/pages/components/Place/CategoriesInput.vue";
import formMixin from "@/core/mixins/formMixin.js";
import placeForm from "@/core/services/forms/placeForm.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";

export default {
  name: "PlaceFormModal",
  components: { LocationInput, CategoriesInput },
  mixins: [formMixin],
  data() {
    return {
      mode: "createPlace",
      avaliableModes: ["createPlace", "editBasicInfo", "editCategories"],
      steps: {
        count: 4,
        current: 1,
        array: [
          "basicInfo",
          "mainCategory",
          "additionalCategories",
          "location",
        ],
      },
    };
  },
  beforeMount() {
    // Dispatch store GET_CATEGORIES action to get categories from api
    this.$store.dispatch(GET_CATEGORIES);
    // Get form object from placeForm service
    this.form = JSON.parse(JSON.stringify(placeForm));
  },
  methods: {
    /**
     * Show the modal
     * @param {String} mode
     * @return {void}
     */
    show(mode) {
      if (this.avaliableModes.includes(mode)) {
        switch (mode) {
          case "createPlace":
            this.steps = this.currentUser.isInLawIndustry ? 
            {
              count: 2,
              current: 1,
              array: [
                "basicInfo",
                "location",
              ],
            } :
            {
              count: 4,
              current: 1,
              array: [
                "basicInfo",
                "mainCategory",
                "additionalCategories",
                "location",
              ],
            };
            break;
          case "editBasicInfo":
            this.steps = {
              count: 1,
              current: 1,
              array: ["basicInfo"],
            };
            break;
          case "editCategories":
            this.steps = {
              count: 2,
              current: 1,
              array: ["mainCategory", "additionalCategories"],
            };
            break;
        }
        this.mode = mode;
      }
      this.$refs.bvModal.show();
    },

    /**
     * Determine if given step name is current step
     * 
     * @param {String} stepName
     * @return {Boolean}
     */
    isCurrentStep(stepName) {
      if(this.steps.array.includes(stepName)) {
        return (this.steps.array.indexOf(stepName) + 1) == this.steps.current
      }

      return false;
    },

    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#place_form_modal_close_btn").click();
    },

    /**
     * Present next step
     * @return {void}
     */
    nextStep() {
      if (this.isLastStep) {
        return;
      }
      this.steps.current++;
    },

    /**
     * Present previous step
     * @return  {void}
     */
    previousStep() {
      if (this.hasPreviousStep) {
        this.steps.current--;
      }

      return;
    },

    /**
     * Update form's mainCategory field
     * @param {String|Number} mainCategoryId
     * @return {void}
     */
    updateMainCategory(mainCategoryId) {
      this.form.fields.mainCategory = mainCategoryId;
      if (this.form.fields.additionalCategories) {
        if (this.form.fields.additionalCategories.includes(mainCategoryId)) {
          let categoryIndex = this.form.fields.additionalCategories.findIndex(
            (id) => id === mainCategoryId
          );
          this.form.fields.additionalCategories.splice(categoryIndex, 1);
        }
      }
    },

    /**
     * Update form's additionalCategories field
     * @param {array} additionalCategoriesIds
     * @return {void}
     */
    updateAdditionalCategories(additionalCategoriesIds) {
      this.form.fields.additionalCategories = additionalCategoriesIds;
    },

    /**
     * Update form's location fields
     * @param {Object} location
     * @return {void}
     */
    updateLocationFields(location) {
      this.form.fields.address = location.address;
      this.form.fields.latitude = location.latitude;
      this.form.fields.longitude = location.longitude;
    },

    /**
     * Activate logoInput
     * @return {void}
     */
    uploadLogo() {
      this.$refs.logoInput.click();
    },

    /**
     * Handle logo upload
     * @param {Object} event
     * @return {void}
     */
    onFileChange(event) {
      this.form.fields.logo = event.target.files[0];
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.submitForm()
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.closeModal();
          this.steps.current = 1;
          this.$store.dispatch(GET_PLACES);
        })
        .catch((error) =>
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          })
        );
    },
  },
  computed: {
    ...mapGetters(["allCategories", "currentUser"]),

    /**
     * Return check if form has multiple steps
     * @return {Boolean}
     */
    hasMultipleSteps() {
      return this.steps.count > 1 ? true : false;
    },

    /**
     * Return string for steps description
     * @return {String}
     */
    stepsString() {
      if (this.hasMultipleSteps) {
        return (
          this.$t("placeFormModal.stepLabel") +
          " " +
          this.steps.current +
          " " +
          this.$t("placeFormModal.stepOfLabel") +
          " " +
          this.steps.count
        );
      }

      return "";
    },

    /**
     * Determine if basic info is visible
     * @return {Boolean}
     */
    basicInfoVisible() {
      if (this.mode === "editBasicInfo") {
        return true;
      }

      if (this.mode === "createPlace") {
        return this.isCurrentStep("basicInfo")
      }

      return false;
    },

    /**
     * Determine if location is visible
     * @return {Boolean}
     */
    locationVisible() {
      if (this.mode === "createPlace") {
        return this.isCurrentStep("location")
      }

      return false;
    },

    /**
     * Determine if main category is visible
     * @return {Boolean}
     */
    mainCategoryVisible() {
      if (this.mode === "createPlace" || this.mode === "editCategories") {
        return this.isCurrentStep("mainCategory")
      }

      return false;
    },

    /**
     * Determine if additional categories is visible
     * @return {Boolean}
     */
    additionalCategoriesVisible() {
      if (this.mode === "createPlace" || this.mode === "editCategories") {
        return this.isCurrentStep("additionalCategories")
      }

      return false;
    },

    /**
     * Return modal title
     * @return {String}
     */
    modalTitle() {
      let title = "";
      switch (this.mode) {
        case "createPlace":
          switch (this.steps.current) {
            case 1:
              title = this.$t("placeFormModal.createPlaceMode.step1.title");
              break;
            case 2:
              title = this.currentUser.isInLawIndustry ? 
                this.$t("placeFormModal.createPlaceMode.step4.title") :
                this.$t("placeFormModal.createPlaceMode.step2.title");
              break;
            case 3:
              title = this.$t("placeFormModal.createPlaceMode.step3.title");
              break;
            case 4:
              title = this.$t("placeFormModal.createPlaceMode.step4.title");
              break;
          }
          break;
        case "editBasicInfo":
          title = this.$t("placeFormModal.editInfoMode.title");
          break;
        case "editCategories":
          switch (this.steps.current) {
            case 1:
              title = this.$t("placeFormModal.editCategoriesMode.step1.title");
              break;
            case 2:
              title = this.$t("placeFormModal.editCategoriesMode.step2.title");
              break;
          }
          break;
      }

      return title;
    },

    /**
     * Return modal title description
     * @return {String|null}
     */
    titleDescription() {
      if (this.mode === "createPlace" && this.steps.current === 3) {
        return this.$t("placeFormModal.createPlaceMode.step3.description");
      }
      if (this.mode === "editCategories" && this.steps.current === 2) {
        return this.$t("placeFormModal.editCategoriesMode.step2.description");
      }

      return null;
    },

    /**
     * Check if it is form's last step
     * @return {Boolean}
     */
    isLastStep() {
      return this.steps.count === this.steps.current;
    },

    /**
     * Check if form has previous step
     * @return {Boolean}
     */
    hasPreviousStep() {
      if (this.steps.count > 1 && this.steps.current !== 1) {
        return true;
      }

      return false;
    },

    /**
     * Return url of uploaded logo
     * @return {String}
     */
    logoUrl() {
      if (this.form.fields.logo) {
        return URL.createObjectURL(this.form.fields.logo);
      }
      return "";
    },

    /**
     * Determine if can go to next step
     * @return {Boolean}
     */
    canGoToNextStep() {
      switch (this.mode) {
        case "createPlace":
          switch (this.steps.current) {
            case 1:
              return this.form.fields.name && this.form.fields.phone;
            case 2:
              return this.form.fields.mainCategory;
            case 3:
              return true;
            case 4:
              return false;
          }
          break;
        case "editCategories":
          switch (this.steps.current) {
            case 1:
              return this.form.fields.mainCategory;
          }
          break;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/place-form-modal.scss";
</style>
